.menuItem {
  margin-bottom: 20px;
  border: 1px solid black;
  padding: 15px 20px !important;
}

.menuItem .p-col-3,
.menuItem .p-col-9 {
  padding: 0 0 0 8px;
}

.ignore-margin {
  margin-right: -15px;
  margin-left: -15px;
}

.txt-input {
  border: 1.5px solid black !important;
  border-radius: 5px !important;
  padding: 10px !important;
  width: 100%;
}
.txt-input::placeholder {
  color: black;
}
.p-dropdown-trigger-icon {
  font-weight: bold;
  font-size: 15px !important;
}
.menuItemName {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #1b1b1b;
}
.menuItemDescription {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #424242;
}
.menuItemPrice {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #424242;
  padding: 17px 0 !important;
}
.loginButton {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #ffd600 !important;
  border: none !important;
  color: black !important;
}

.registerDialog {
  width: 80vh;
  height: 90vh;
  margin: 10px;
}

.registerDialog .p-dialog-titlebar {
  border: none !important;
  background: white !important;
}

.registerDialog .p-dialog-content {
  border: none !important;
}

.productPopupDescription {
  padding-bottom: 15px;
}

.productPopupQuantity {
  padding-bottom: 15px;
}

.p-inputnumber-input {
  text-align: center;
}

.p-inputtext {
  font-size: 18px !important;
}

.p-sidebar-close-icon {
  font-weight: bold;
  color: white;
}

.p-sidebar a {
  color: white !important;
  font-size: 18px;
  font-weight: bold;
}

.p-sidebar ul {
  font-size: 24px;
}

.p-sidebar {
  background-color: #a50043 !important;
  width: 100%;
}

body.p-link:focus {
  box-shadow: none !important;
}

.p-sidebar li {
  margin-bottom: 20px;
  list-style-type: none;
  margin-top: 30px;
  text-align: center;
}
.shoppingCart {
  text-align: center;
  width: 100%;
  color: #666;
}
.google {
  color: white !important;
  background-color: rgb(40, 93, 207) !important;
  font-size: 16px !important;
  font-weight: bold !important;
  margin: auto;
  display: block ruby !important;
  margin-top: 20px !important;
  border-radius: 10px !important;
  height: 60px;
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.google span {
  font-weight: bold !important;
}

.google div {
  margin-left: 10px;
  padding: 5px !important;
}

html {
  background-color: #fafafa;
}

.p-dialog {
  background-color: #fafafa !important;
}

.sectorButton {
  color: #1b1b1b !important;
  background-color: #ffd600 !important;
  font-weight: bold;
  width: 100% !important;
  border-style: none !important;
  height: 50px;
  border-radius: 5px !important;
  margin-top: 25px !important;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  font-family: "Roboto", sans-serif !important;
  color: #1b1b1b;
}
.submessage {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;

  color: #1b1b1b;
}

#sectorList,
#standList,
.telephone-field input {
  border: 2px solid #1b1b1b;
  box-sizing: border-box;
  border-radius: 5px;
}

#standList label,
#sectorList label,
.telephone-field input {
  font-size: 14px !important;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  color: #1b1b1b;
}

.p-dropdown-trigger-icon {
  color: #1b1b1b;
}
body .p-dropdown {
  border: 2px solid #1b1b1b;
  box-sizing: border-box;
  border-radius: 5px;
}

.warning-message {
  color: orange;
  border: 1px solid orange;
  padding: 5px;
}

.page-wrapper {
  min-height: 100%;
  margin-bottom: 90px;
}

.page-wrapper:after {
  content: "";
  display: block;
}
.checkout-footer,
.page-wrapper:after {
  height: 45px;
}
.loginButtons {
  margin: auto;
  display: flex;
  flex-direction: column;
}
.loginButtons .p-as-center.p-mb-2 {
  width: 100%;
}
.loginButtons .p-as-center.p-mb-2 button {
  width: 100%;
}
.remember-chkbox + label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #424242;
}
.sectorHeader {
  text-align: center;
  text-align: center;
  width: 238px;
  margin: auto;
  margin-top: 130px !important;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 65px;
}
.forgotPasswordHeader {
  text-align: center;
  width: 238px;
  margin: auto;
  margin-top: 0 !important;
  margin-bottom: 60px;
  justify-content: center;
  align-items: center;
  position: relative;
}
.loginTitle {
  text-align: center;
  text-align: center;
  width: 238px;
  margin: auto;
  margin-top: 50px !important;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 65px;
}
.sectorHeader h2,
.loginTitle h2,
.forgotPasswordHeader h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #1b1b1b;
  padding-left: 5px;
}
.sectorHeader::after,
.forgotPasswordHeader::after {
  content: "";
  border-bottom: 4px solid #ffd600;
  width: 45%;
  position: absolute;
  bottom: -17px;
}
.loginTitle::after {
  content: "";
  border-bottom: 4px solid #ffd600;
  width: 45%;
  position: absolute;
  bottom: -18px;
}
.sectorHeader .circle,
.loginTitle .circle {
  background-color: #ffd600;
  border-radius: 100%;
  height: 30px;
  width: 30px;
}
.sectorHeader .circle img {
  padding: 6px 7px;
}
.loginTitle .circle img {
  padding: 6px;
}
.sector {
  display: flex;
}

.p-field label {
  font-size: 16px;
  font-weight: bold;
}

.addSelect {
  background-color: #fafafa !important;
  margin-top: 70px !important;
}

.productPopupQuantity {
  padding-bottom: 0px !important;
}

.selectionAdd button {
  font-weight: bold;
  font-size: 16px;
}
.selectionAdd {
  position: fixed;
  bottom: 75px;
  width: 95%;
  z-index: 100;
}
.kep-login-facebook .fa {
  padding: 10px;
}

.kep-login-facebook {
  color: white !important;
  font-size: 16px !important;
  font-weight: bold !important;
  padding: 0px !important;
  height: 60px !important;
  width: 230px;
  margin: auto;
  text-transform: capitalize !important;
  border-radius: 10px !important;
}

.headerLogin {
  background-color: #a50043;
  text-align: center;
  height: 70px;
  display: flex;
  justify-content: center;
}

.header {
  display: flex;
  height: 70px;
  background-color: rgb(165, 0, 67);
  width: 100%;
  position: fixed;
  left: 0;
  z-index: 100;
  justify-content: center;
  top: 0;
}

.p-checkbox-box {
  color: #1b1b1b !important;
  border-radius: 100% !important;
}
body .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #1b1b1b;
  background-color: #fff;
}
body .p-checkbox .p-checkbox-box.p-highlight {
  /* border-color: #FFD600 !important;
  background-color: rgb(165, 0, 67) !important; */
  border-color: #000 !important;
  background-color: #ffd600 !important;
}
.pi-check:before {
  color: transparent !important;
}
.telephone-field {
  margin-bottom: 55px;
}
.telephone-field input {
  margin-bottom: 5px;
  border: 2px solid #1b1b1b;
  box-sizing: border-box;
  border-radius: 5px;
}
.telephone-field input::placeholder {
  font-size: 14px !important;
  height: 50px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  color: #1b1b1b;
}
.telephone-field span {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #1b1b1b;
}
.topbar-title-menu .merchant-name-menu {
  border-right: 2px solid #000;
  margin-right: 10px;
  margin-bottom: 10px;
}
.topbar-title-menu .merchant-name-menu .merchant-name {
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #1b1b1b;
  margin-top: 4px;
}
.topbar-title-menu .merchant-name-menu img {
  width: 14px;
}
.topbar-title-menu .sector-name-menu {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #1b1b1b;
  margin-top: 4px;
}
.topbar-title-menu button {
  margin: unset;
}
.cart-button .pi-shopping-cart:after {
  content: "Καλάθι / Cart";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 21px;
  font-family: "Roboto", sans-serif !important;
  color: #1b1b1b;
  padding-left: 10px;
}
.cart-button .pi-shopping-cart:before {
  font-size: 21px;
}
.cart-button .p-button-text {
  float: right;
}
.p-dialog-mask.p-component-overlay.p-dialog-visible.p-dialog-bottom {
  background-color: unset;
  transition: all ease-in-out 0.7s;
}
.p-dialog-mask.p-component-overlay.p-dialog-visible.p-dialog-bottom
  .p-component.p-dialog-enter-done {
  /* height: 100%; */
}
/* .loginTitle {
  text-align: center;
  border-bottom: 4px solid #FFD600;
  width: 110px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
} */

.loginTitle img {
  padding: 10px;
}

.centerAlign {
  display: flex;
  align-items: center;
}

.orderTitle {
  box-shadow: 0 4px 2px -2px gray;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 70px;
  text-align: center;
}

.cartTitle {
  box-shadow: 0 4px 2px -2px gray;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-top: 70px;
  display: flex;  
  align-items: center;
}

.p-accordion-header a {
  background-color: #ffd600 !important;
  color: #1b1b1b !important;
}

.p-accordion-toggle-icon {
  color: #1b1b1b !important;
}

.p-accordion-content {
  background-color: #fafafa !important;
}

.p-accordion-content div {
  font-weight: bold;
}

.cartTitle {
  text-align: center;
}

.cartItem ul {
  margin-left: 12px;
}

.p-dialog-content ul {
  padding-left: 0px;
}

.cartButton {
  background-color: #ffd600 !important;
  color: #1b1b1b !important;
  border-style: none !important;
  width: 40px;
  height: 40px;
}

.cartButtonAdd {
  font-size: 10px !important;
}

.p-dialog-footer a {
  color: #1b1b1b;
}

.p-dialog-footer a:hover {
  color: #ffd600 !important;
}

.quanButton {
  margin-left: 5px !important;
}

.cartUl {
  padding-left: 20px !important;
}

.coffeeUl {
  padding-left: 0px;
}

.cartButtonClose {
  background-color: transparent !important;
  border-style: none !important;
  color: gray !important;
}

.p-dialog-titlebar-close {
  background-color: #ffd600 !important;
  color: #1b1b1b !important;
}

.viva-container {
  margin: 10px;
  justify-content: space-around;
  border: dashed 1px #ffd600;
  border-radius: 10px;
  height: 80px;
  align-items: center;
  background: #ffd600;
}
.viva-container img {
  width: 90px;
  height: 50px;
}
.registerDialog .p-dialog-titlebar-icons {
  width: 100%;
}
.registerDialog .p-dialog-titlebar-close {
  float: right !important;
}

.p-button-success {
  border-style: none !important;
}

.selectorItem .p-sidebar-close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black !important;
  background-color: #ffd600;
  width: 40px;
  height: 40px;
}

.p-inputnumber-button-icon {
  color: black;
}

.p-inputnumber-button {
  background-color: #fafafa !important;
  border-style: none !important;
}

.p-inputnumber .p-inputtext {
  border-color: #ffd600;
  border-width: 2px;
}

.spreader {
  border-top: 2px solid #ffd600;
}
.success-arrow {
  margin-right: 10px;
  background: #ffd600;
  padding: 3px;
  border-radius: 20px;
  color: black;
}
.p-link:focus {
  outline: none !important;
  box-shadow: none !important;
}

.p-inputtext:hover {
  border-style: 2px solid #ffd600 !important;
}

.close-button {
  background: transparent !important;
  border: none !important;
  float: right !important;
}

.close-button .pi-times {
  color: black;
  background: #ffd600;
}

.totalPrice {
  border-top: 2px solid #c4c4c4;
  padding-bottom: 15px;
  margin-top: 20px;
}

.totalPriceCart {
  border-top: 2px solid #c4c4c4;
  padding-top: 20px;
}

.invalid-input {
  border-color: red !important;
}

.cartItemPrice {
  margin-left: 10px;
}

.form-row input[type="text"] {
  width: 100%;
  border: 2px solid #ffd600;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
}
.form-row input[type="text"]:focus {
  border: 2px solid black;
}

.form-row input[type="number"] {
  width: 100%;
  border: 2px solid #ffd600;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
}
.form-row input[type="number"]:focus {
  border: 2px solid black;
}

.expiry-input {
  border: 2px solid #ffd600;
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
  margin-right: 10px;
}

.expiry-input:focus {
  border: 2px solid black;
}
.credit-card {
  padding: 0.5rem;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.2);
}
.card-selector {
  border: 1.5px solid black;
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
  margin-right: 10px;
}

.productModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#threed-pane {
  width: 100%;
}
.p-progress-spinner {
  display: block !important;
}

/* GENERAL */
body .p-button {
  border-radius: 5px !important;
}

/* LOGIN PAGE */

/* ORDER TYPE BUTTON */
.order-type {
  text-align: center;
}

.order-type .p-button {
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: center !important;
  color: rgba(27, 27, 27, 0.15) !important;
  background-color: transparent !important;
  border: 0 !important;
}

.order-type .p-button.p-highlight {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: center !important;
  color: #1b1b1b !important;
  border-bottom: 4px solid #ffd600 !important;
}

/* FORGOT PASSWORD */
button.p-button.p-component.forgotPasswordButton.p-button-text-only {
  background: transparent;
  border: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #a50043;
}

.forgotPasswordDialog .p-dialog-titlebar {
  border: none !important;
  background: white !important;
}

.forgotPasswordDialog .p-dialog-titlebar-close {
  background-color: transparent !important;
}

.forgotPasswordDialog .p-dialog-content {
  border: none !important;
  background: white !important;
}

.response_message {
  text-align: center;
  color: red;
  padding: 20px;
}

.edit-user-page input {
  border: 2px solid #1B1B1B;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #1B1B1B;
}

.edit-user-page label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #1B1B1B;
}

.edit-user-page button {
  background-color: rgb(23, 135, 0);
  border: 1px solid rgb(23, 135, 0);
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  height: 50px;
  margin-top: 20px;
}

.edit-user-page button:hover, .edit-user-page button:active {
  background-color: #ffd600!important;
  border: 1px solid #ffd600!important;
  color: #000!important;
}

.removeCoupon {
  margin-left: 15px;
}

.p-grid {
  align-items: center;
}

.mainNavIcons {
  font-size: 1.25em;
    background: #FFD600;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    vertical-align: middle;
    margin-right: 6px;
}

.mainNavIcons::before {
  font-weight: 900;
}

.p-sidebar li {
  text-align: left;
  display: flex;
  align-items: center;
}

.p-sidebar.p-component.p-sidebar-left.p-sidebar-active .spreader {
  margin-bottom: 40px;
}

.cartTitleSpan {
  position: relative;
  margin: auto;
  left: -20px;
}

.privacyLink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: -50px;
}

.privacyButton {
  vertical-align: middle;
  max-width: 183px;
  width:100%;
  height: 50px;
  background: #FFD600;
  border-radius: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  border: 0;
}

@keyframes ui-progress-spinner-color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}

@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="url"] {
    font-size: 16px;
  }
}

input,
select {
  font-size: 100%;
}
